import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery';

import LoginView from '@/views/Login/LoginView.vue';
import PlatformView from '@/views/Platform/PlatformView.vue';
import LiveGamesView from '@/views/LiveGames/LiveGamesView.vue';

import ManufacturersView from '@/views/Platform/Manufacturers/ManufacturersView.vue';
import SlotsView from '@/views/Platform/Slots/SlotsView.vue';
import PlayView from '@/views/Platform/Play/PlayView.vue';

import LayoutSimView from '@/views/Platform/LayoutSim/LayoutSimView.vue';
import LayoutTestView from '@/views/Platform/LayoutTest/LayoutTestView.vue';
import KeyPadView from '@/views/Platform/KeyPad/KeyPadView.vue';
import KeyPadScan from '@/views/Platform/KeyPad/KeyPadScan.vue';

import PairTvView from '@/views/Platform/PairTv/PairTvView.vue';

import MenuView from '@/views/Platform/Menu/MenuView.vue';
import ProfileView from '@/views/Platform/Menu/Profile/ProfileView.vue';
import SessionsView from '@/views/Platform/Menu/Sessions/SessionsView.vue';
import TransactionsView from '@/views/Platform/Menu/Transactions/TransactionsView.vue';


Vue.use(VueRouter)

const routes = [
    // Default redirect
    {
        path: '',
        redirect: 'platform',
    },
    // Login
    {
        path: '/login',
        component: LoginView,
        meta: { requiresGuest: true }, 
    },
    // Platform
    {
        path: '/keypad/session/:id',
        component: KeyPadView,
    },
    {
        path: '/keypad/scan',
        component: KeyPadScan,
    },
    {
        path: '/live-games',
        component: LiveGamesView,
    },
    {
        path: '/platform/',
        component: PlatformView,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: '/platform/manufacturers',
            },
            {
                path: 'menu',
                component: MenuView,
                children: [
                    {
                        path: 'profile',
                        component: ProfileView,
                    },
                    {
                        path: 'transactions',
                        component: TransactionsView,
                    },
                    {
                        path: 'sessions',
                        component: SessionsView,
                    },
                ]
            },
            {
                path: 'manufacturers',
                component: ManufacturersView,
            },
            {
                path: 'manufacturers/:id',
                component: SlotsView,
            },
            {
                path: 'session/:id',
                name: 'PlayRoute',
                component: PlayView,
            },
            {
                path: 'layout/:id',
                name: 'LayoutSim',
                component: LayoutSimView,
            },
            {
                path: 'layout-test',
                name: 'LayoutTest',
                component: LayoutTestView,
            },
            {
                path: 'pair-tv/:id',
                name: 'PairTV',
                component: PairTvView,
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    // Close modals on route change 
    $('.modal').modal('hide');   

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (router.app.$store.getters.loggedIn === false) {

            next({
                path: '/login',
            })
        } else { 
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (router.app.$store.getters.loggedIn === true) {

            next({
                path: '/',
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router