<template>
    <div class="component auth-img">
        <div class="image-data" :class="{'cover': cover}" v-if="imageData" v-bind:style="{ 'background-image': 'url(' + imageData + ')' }">
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "AuthImg.scss";
</style>
<script>
import { Buffer } from 'buffer';
export default {
    name: "AuthImg",
    props: ['src', 'cover'],
    data() {
        return {
            imageData: null,
        }
    },
    async mounted() {
        await this.getImage(this.src);
    },
    methods: {
        async getImage(src) {
            try {
                let response = await this.$http({
                    method: 'get',
                    url: src,
                    responseType: 'arraybuffer',
                });
                var mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this.imageData = 'data:' + mimeType + ';base64,' + imgBase64;
            } catch (error) {
                console.log('auth image error:', error)
            }
        },
    }
}
</script>