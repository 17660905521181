<template>
    <div class="view login h-100" :style="'background-image: url(' + background + ')'">
        <div class="container h-100">
            <div class="row justify-content-center align-content-center h-100">
                <div class="col col-md-8 col-lg-6 col-xl-5">
                    <div class="card-login" data-aos="fade-down">
                        <div class="card-body">
                            <h5 v-if="logo" class="card-title" style="background-color: none !important;">
                                <img class="logo" :src="logo" alt="">
                            </h5>
                            <div class="phone" v-if="contact.phone" style="text-align: center;">
                                 <a :href="contact.whatsapp" target="_blank" style="color: #d1a391;">
                                    {{ contact.phone }}
                                </a>
                            </div>
                            <div class="form">
                                <div class="fields">
                                    <form ref="loginForm" @submit="formSubmit">
                                        <div class="form-group">
                                            <input v-model="credentials.username" id="loginUsernameField" class="form-control" placeholder="User ID" required>
                                        </div>
                                        <div class="form-group">
                                            <input v-model="credentials.password" id="loginPasswordField" type="password" class="form-control" placeholder="Password" required>
                                        </div>
                                        <!-- <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                            <label class="form-check-label" for="exampleCheck1">Remember me</label>
                                        </div> -->
                                        <br>
                                        <button type="submit" class="btn btn-primary form-control">Login</button>
                                    </form>
                                </div>
                                <div class="actions">
                                </div>
                            </div>
                            <div class="icons">
                                <div class="icon" v-if="contact.whatsapp">
                                    <a :href="contact.whatsapp" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                            <path fill="#d1a391" d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.82 9.82 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.23 8.23 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.2 8.2 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18s.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01" /></svg>
                                    </a>
                                </div>
                                <div class="icon" v-if="contact.telegram">
                                    <a :href="contact.telegram" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                            <path fill="none" stroke="#d1a391" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m11.985 15.408l3.242 3.686c1.2 1.365 1.801 2.048 2.43 1.881c.628-.166.844-1.064 1.275-2.861l2.39-9.968c.665-2.768.997-4.151.259-4.834s-2.017-.175-4.575.84L5.14 8.865c-2.046.813-3.069 1.219-3.134 1.917a1 1 0 0 0 0 .214c.063.699 1.084 1.108 3.128 1.927c.925.371 1.388.557 1.72.912q.056.06.108.124c.306.38.436.88.697 1.876l.489 1.867c.253.97.38 1.456.713 1.522s.622-.336 1.201-1.141zm0 0l-.317-.33c-.362-.378-.543-.566-.543-.8s.18-.423.543-.8l3.573-3.724" color="currentColor" /></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { mapGetters, mapActions } from 'vuex';
import { Buffer } from 'buffer';
export default {
    data() {
        return {
            visible: false,
            loading: false,
            errors: [],
            credentials: {
                username: '',
                password: '',
            },
            logo: null,
            background: null,
            contact: {
                whatsapp: null,
                telegram: null,
                phone: null,
            }
        }
    },
    computed: mapGetters(['loggedIn']),
    mounted() {

        this.delete_cookie('hide_popup');

        if(process.env.VUE_APP_CONTACT_WHATSAPP) {
            this.contact.whatsapp = process.env.VUE_APP_CONTACT_WHATSAPP;
        }

        if(process.env.VUE_APP_CONTACT_TELEGRAM) {
            this.contact.telegram = process.env.VUE_APP_CONTACT_TELEGRAM;
        }

        if(process.env.VUE_APP_CONTACT_PHONE) {
            this.contact.phone = process.env.VUE_APP_CONTACT_PHONE;
        }

        this.getLogo();
        this.getBackground();
    },
    methods: {
         delete_cookie(name) {
          document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },

        ...mapActions(['login']),
        show() {
            this.visible = true;
            this.$nextTick(() => {
                $("#loginUsernameField").focus();
            });


        },
        hide() {
            this.visible = false;
        },
        async getLogo() {
            try {
                let response = await this.$http({
                    method: 'get',
                    url: '/unigens/download_picture_by_key?path=CASINO_PLAYER&key=SYS_LOGIN_LOGO',
                    responseType: 'arraybuffer',
                });

                let mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this.logo = 'data:' + mimeType + ';base64,' + imgBase64;
            } catch (error) {
                console.log(error);
            }
        },
        async getBackground() {
            try {
                let response = await this.$http({
                    method: 'get',
                    url: '/unigens/download_picture_by_key?path=CASINO_PLAYER&key=SYS_LOGIN_BACKGROUND',
                    responseType: 'arraybuffer',
                });

                let mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this.background = 'data:' + mimeType + ';base64,' + imgBase64;
            } catch (error) {
                console.log(error);
            }
        },
        formSubmit(e) {

            e.preventDefault();

            this.loading = true;
            this.errors = [];

            this.login(this.credentials)
                .then(res => {
                    this.loading = false;
                    this.visible = false;
                    this.$router.push('/platform');
                    window.scrollTo(0, 0);

                })
                .catch(error => {
                    this.loading = false;
                    this.$toasted.error('Username or password incorerect');

                });

        }
    }
}
</script>