<template>
    <div class="view app">
        <div class="live-games">
            
            <iframe 
            style="display: inline-block; border: none; margin-left: calc((100vw - 1400px) / 2); margin-top: calc((100vh - 900px) / 2)"
            allow="autoplay; fullscreen"
            :src="`https://center-int.reallivegame.com/?userKey=${$route.query.userKey}&lang=en&tableId=${$route.query.tableId}&gameType=${$route.query.gameType}&public=1hamchg7kov0h4u6sd6rf&hasLobby=false&homeUrl=https://casinosavoy.cloud.ro`" width="1400" height="900" align="left"></iframe>
            
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
    name: "LiveGamesView",
    data() {
        return {
        }
    },
    async mounted() {
        window.alg.redirect = (homeUrl) => {
            this.$router.push('/');
        }
    },
    methods: {
    }
}
</script>